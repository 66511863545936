import _ from 'lodash'
import { get } from 'lodash/fp'
import moment from 'moment'
import { all, call, put, race, select, take, takeEvery } from 'redux-saga/effects'
import { getPolicyPayload } from './policy-payload'
import { confirmDialog } from './confirm'
import { getIdentityUser } from 'identity/selectors'
import { APP_STATUS } from 'e-submission/domain/data-model/constants'
import { createPdfDoc, getBase64FromPdfDoc } from 'pdf-generation/pdf-service'
import {
  getAllPhoto,
  getAllDocument,
  getCurrentApp,
  getCurrentAppId,
  getAllPhotoIlp,
  getPolicyIdCurrentApp,
  getAIUWCurrentApp,
  getRequiredUWByDoc,
  isFinishedPaymentSection,
} from 'e-submission/apps/selectors'
import {
  getAttachments,
  getEsubPdfProps,
  getPolicyId,
  getQuickQuotePdfProps,
  isSelectedRemoteSelling,
  isCompletedPaid,
} from 'e-submission/domain/data-model/form/selectors'
import {
  hideLoading,
  setAppStatusSigned,
  setOnOtp,
  showDialog,
  showLoading,
  setDefaultAppValue,
  startSubmitPage,
  setAppValue,
  saveAppData,
  requestAIUW,
} from 'e-submission/apps/actions'
import { saveAIUWRequest, getAIUWStatus } from 'e-submission/domain/service/ai-uw'

import { onListDocument } from './document'
import { blobToText } from 'e-submission/utils'
import CleaveUtil from 'cleave.js/src/utils/Util'
import { getToggles } from 'quick-quote/feature-toggles'
import { isNil } from 'core/service/lib/type-check'
import VALUES from 'core/data-model/constants/values'
// import { updateOptyStageKyc } from 'quick-quote/opty/actions'
import ROUTE_PATH from 'quick-quote/constants/path'
import { delay } from 'redux-saga'

const parseJSON = (json) => {
  try {
    return JSON.parse(json)
  } catch (e) {
    return {}
  }
}

export const generatePdfProps = (quickQuotePdfProps, esubProps, signDate) => {
  const propsWithoutSignDate = _.merge(quickQuotePdfProps, esubProps)

  const hasNoPayer = _.isEmpty(propsWithoutSignDate.payer)
  const hasNoStaff = _.isEmpty(propsWithoutSignDate.staff)

  const signDateInjection = _.merge(
    { insured: { signDate } },
    { profile: { signDate } },
    hasNoPayer ? {} : { payer: { signDate } },
    hasNoStaff ? {} : { staff: { signDate } }
  )

  return _.merge(propsWithoutSignDate, signDateInjection)
}

const getCurrentDateStrInBuddhist = () => {
  return moment()
    .add(543, 'years')
    .format('DD/MM/YYYY')
}

const getPdfPropsOfQuickQuote = function*(app, service) {
  let quickQuotePropsJson = getQuickQuotePdfProps(app)
  if (_.isNil(quickQuotePropsJson)) {
    const user = yield select(getIdentityUser)
    const data = yield call(service.getAttachment, app, 'bi', user)
    quickQuotePropsJson = yield call(blobToText, new Blob([data]))
  }
  return parseJSON(quickQuotePropsJson)
}

const fetchBiData = function*(service) {
  const app = yield select(getCurrentApp)
  const quickQuotePdfProps = yield call(getPdfPropsOfQuickQuote, app, service)
  const esubPdfProps = getEsubPdfProps(app)
  const pdfProps = generatePdfProps(quickQuotePdfProps, esubPdfProps, getCurrentDateStrInBuddhist())
  const pdfDoc = yield call(createPdfDoc, pdfProps)
  return yield call(getBase64FromPdfDoc, pdfDoc)
}

const listDocument = function*(getBiData) {
  const documents = yield select(getAllDocument)
  const hasBI = documents.some((document) => document.name === 'bi')
  let biData = null
  if (hasBI) {
    biData = yield call(getBiData)
  }

  return _.map(documents, (document) =>
    document.name === 'bi'
      ? {
          code: document.name,
          data: biData,
        }
      : {
          code: document.name,
        }
  )
}

const getDocuments = function*(service) {
  return yield call(listDocument, function*() {
    return yield call(fetchBiData, service)
  })
}

const getReqPayload = function*(service, getPayloadFn, getDocumentsFn) {
  const state = yield select()
  const reqPayload = yield call(getPayloadFn, state)
  const applicationId = yield select(getCurrentAppId)
  const documents = yield call(getDocumentsFn, service)
  return {
    applicationId,
    documents,
    ...reqPayload,
  }
}

export const onCreatePolicy = function*(service, getPayloadFn, getDocumentsFn) {
  const { createPolicy } = service
  yield put.resolve(showLoading())
  try {
    const user = yield select(getIdentityUser)
    const reqPayload = yield call(getReqPayload, service, getPayloadFn, getDocumentsFn)
    const remoteSelling = get('remoteSelling', reqPayload)
    const quickQuoteData = get('quickQuote', reqPayload)
    if (!isNil(quickQuoteData.category) && quickQuoteData.category !== VALUES.MRTA) {
      if (isNil(remoteSelling?.caseId)) yield put(setAppValue('remoteSelling.ekyc', remoteSelling.caseId))
    }
    const policy = yield call(createPolicy, user, reqPayload)
    if (!isNil(policy.policyId)) {
      yield [put(setAppValue('policyId', policy.policyId)), take('SAVE_APPLICATION_SUCCESS')]
      yield put({ type: 'FETCH_SIGNATURE' })
    }
    yield put({ type: 'CREATE_POLICY_SUCCEED', policyID: policy.policyId })
  } catch (e) {
    console.error('Create policy failed', e)
    yield put({ type: 'CREATE_POLICY_FAILED', message: e.message })
  } finally {
    yield put(hideLoading())
  }
}

export const onUpdatePolicy = function*(service, getPayloadFn, getDocumentsFn) {
  const { updatePolicy } = service
  try {
    const app = yield select(getCurrentApp)
    const policyId = getPolicyId(app)
    const user = yield select(getIdentityUser)
    const reqPayload = yield call(getReqPayload, service, getPayloadFn, getDocumentsFn)
    const policy = yield call(updatePolicy, user, policyId, reqPayload)
    yield put({ type: 'UPDATE_POLICY_SUCCEED', policyID: policy.policyId })
  } catch (e) {
    yield put({ type: 'UPDATE_POLICY_FAILED', message: e.message })
  }
}

export const waitTillSuccessOrFailed = function*(action) {
  const [{ success }] = yield [
    race({
      success: take('SAVE_APPLICATION_SUCCESS'),
      failed: take('SAVE_APPLICATION_FAIL'),
    }),
    put(action()),
  ]
  return success
}

export const onVerifyPolicy = function*(service, action) {
  const app = yield select(getCurrentApp)
  const mobileNumber = _.get(app, 'insured.mobileNumber')
  const blocks = [3, 3, 4]
  const formmatedMobileNumber = CleaveUtil.getFormattedValue(mobileNumber, blocks, blocks.length, '-', [])
  const confirmed = yield call(confirmDialog, {
    title: 'คุณแน่ใจที่จะยืนยันตัวตนลูกค้า ?',
    body: `<p>SMS OTP จะถูกส่งไปเบอร์ ${formmatedMobileNumber}</p>
          <p><strong>เมื่อยืนยันแล้ว ตัวแทน/นายหน้าประกันชีวิตจะไม่สามารถเปลี่ยนแปลงข้อมูลภายในใบคำขอนี้ได้</strong></p>`,
  })
  if (!confirmed) {
    return
  }

  yield put.resolve(showLoading())
  const documents = yield call(onListDocument, service)
  const isNoSignature = documents.every(({ signature }) => _.isEmpty(signature))
  if (isNoSignature) {
    yield put(hideLoading())
    yield put(showDialog({ name: 'Error', type: 'failed' }))
    return
  }

  const setOnOtpSuccess = yield call(waitTillSuccessOrFailed, setOnOtp)
  if (!setOnOtpSuccess) {
    yield put(hideLoading())
    yield put(showDialog({ name: 'Error', type: 'failed' }))
    return
  }
  const setAppSuccess = yield call(waitTillSuccessOrFailed, setAppStatusSigned)
  if (!setAppSuccess) {
    yield put(hideLoading())
    yield put(showDialog({ name: 'Error', type: 'failed' }))
    return
  }
  yield put(startSubmitPage())
}

export const onConfirmSubmittedPolicy = function*(service, history) {
  // might update here
  const { patchPolicy, retrieveEkycReport, updatePolicyNumber } = service
  const app = yield select(getCurrentApp)
  const isAppmanRemoteSelling = isSelectedRemoteSelling(app)
  if (!isAppmanRemoteSelling) {
    const confirmed = yield call(confirmDialog, {
      title: 'คุณแน่ใจที่จะยืนยันตัวตนลูกค้า ?',
      body:
        '<p><strong>เมื่อยืนยันแล้ว ตัวแทน/นายหน้าประกันชีวิตจะไม่สามารถเปลี่ยนแปลงข้อมูลภายในใบคำขอนี้ได้</strong></p>',
    })
    if (!confirmed) {
      return
    }
  }
  yield put.resolve(showLoading())
  // yield put(updateOptyStageKyc())

  const documents = yield call(onListDocument, service)
  const isNoSignature = documents.every(({ signature }) => _.isEmpty(signature))
  if (isNoSignature) {
    yield put(hideLoading())
    yield put(showDialog({ name: 'Error', type: 'failed' }))
    return
  }

  // update signed status
  const user = yield select(getIdentityUser)
  const policyId = getPolicyId(app)
  const reqPayload = {
    status: APP_STATUS.SIGNED,
  }
  let policy
  try {
    policy = yield call(patchPolicy, user, policyId, reqPayload)
    if (isAppmanRemoteSelling) {
      history.push(ROUTE_PATH.REMOTE_SELLING.VIDEO_CONSENT)
    }
  } catch (e) {
    yield put(hideLoading())
    yield put(showDialog({ name: 'Error', type: 'failed' }))
    return
  }
  if (!policy) {
    yield put(hideLoading())
    yield put(showDialog({ name: 'Error', type: 'failed' }))
    return
  }

  if (getToggles().ENABLE_EKYC_F2F) {
    const _attachments = getAttachments(app)
    try {
      const insuredProprietorId = _.get(app, 'ekycInfo.insured.proprietorId', '')
      const applicationId = yield select(getCurrentAppId)
      if (insuredProprietorId) {
        // update policyId to mac-case-keeper
        const policyIdCaseKeeper = _.get(app, 'ekycInfo.insured.policyId', '')
        const payload = {
          policyId: policyIdCaseKeeper,
          policyNumber: policyId,
        }
        yield call(updatePolicyNumber, user, payload)
        const attachmentId = 'insured-ekyc'
        // retrieve ekyc report
        const insuredPayload = {
          proprietorId: insuredProprietorId,
          applicationId,
          attachmentId,
        }
        yield call(retrieveEkycReport, user, insuredPayload)
        _attachments[attachmentId] = {
          content_type: 'application/pdf',
          key: `${applicationId}/${attachmentId}`,
        }
        yield put(setAppValue('_attachments', _attachments))
      }
      const payerProprietorId = _.get(app, 'ekycInfo.payer.proprietorId', '')
      if (payerProprietorId) {
        // update policyId to mac-case-keeper
        const policyIdCaseKeeper = _.get(app, 'ekycInfo.payer.policyId', '')
        const payload = {
          policyId: policyIdCaseKeeper,
          policyNumber: policyId,
        }
        yield call(updatePolicyNumber, user, payload)

        // retrieve ekyc report
        const attachmentId = 'payer-ekyc'
        const payerPayload = {
          proprietorId: payerProprietorId,
          applicationId,
          attachmentId,
        }
        yield delay(1000)
        yield call(retrieveEkycReport, user, payerPayload)
        _attachments[attachmentId] = {
          content_type: 'application/pdf',
          key: `${applicationId}/${attachmentId}`,
        }
        yield put(setAppValue('_attachments', _attachments))
      }
      yield [put({ type: 'FETCH_CURRENT_APP' }), take('LOAD_APP')]
    } catch (error) {
      console.error('onConfirmSubmittedPolicy failed', error.message)
    } finally {
    }
  }
  const setAppSuccess = yield call(waitTillSuccessOrFailed, setAppStatusSigned)
  if (!setAppSuccess) {
    yield put(hideLoading())
    yield put(showDialog({ name: 'Error', type: 'failed' }))
    return
  }
  yield put(hideLoading())
  yield put(startSubmitPage())
}

export const onSubmitPolicy = function*({ patchPolicy }) {
  try {
    const photos = yield select(getAllPhoto)
    const app = yield select(getCurrentApp)
    const policyId = getPolicyId(app)
    const user = yield select(getIdentityUser)
    const requiredAttachments = getAllPhotoIlp(photos, app)
    const policy = yield call(patchPolicy, user, policyId, {
      requiredAttachments,
      status: getToggles().ENABLE_BACKGROUND_JOB ? 'submitting' : 'submitted',
    })

    yield put({ type: 'SUBMIT_POLICY_SUCCEED', data: policy })
  } catch (e) {
    yield put({
      type: 'SUBMIT_POLICY_FAILED',
      message: _.get(e, 'response.data'),
    })
  }
}

export const onStartRemoteSubmitPage = function*(service, getPolicyPayload) {
  // not sure use or not
  const app = yield select(getCurrentApp)
  const { patchPolicy } = service
  yield put.resolve(showLoading())
  const documents = yield call(onListDocument, service, { payload: { isFetch: true } })
  const isNoSignature = documents.every(({ signature }) => _.isEmpty(signature))
  if (isNoSignature) {
    yield put(hideLoading())
    yield put(showDialog({ name: 'Error', type: 'failed' }))
    return
  }

  // update signed status
  const user = yield select(getIdentityUser)
  const policyId = getPolicyId(app)
  const reqPayload = {
    status: APP_STATUS.SIGNED,
  }
  let policy
  try {
    policy = yield call(patchPolicy, user, policyId, reqPayload)
  } catch (e) {
    yield put(hideLoading())
    yield put(showDialog({ name: 'Error', type: 'failed' }))
    return
  }
  if (!policy) {
    yield put(hideLoading())
    yield put(showDialog({ name: 'Error', type: 'failed' }))
    return
  }

  const setAppSuccess = yield call(waitTillSuccessOrFailed, setAppStatusSigned)
  if (!setAppSuccess) {
    yield put(hideLoading())
    yield put(showDialog({ name: 'Error', type: 'failed' }))
    return
  }
  yield put(hideLoading())
  yield put(startSubmitPage())
}

export const onStartSubmitPage = function*() {
  const ENABLE_PREFILL_DDA_FORM = getToggles().ENABLE_PREFILL_DDA_FORM
  const ENABLE_PREFILL_IDTYPE_DROPDOWN_DA_FORM = getToggles().ENABLE_PREFILL_IDTYPE_DROPDOWN_DA_FORM
  const ENABLE_AIUW = getToggles().ENABLE_AIUW

  if (ENABLE_PREFILL_DDA_FORM) {
    const app = yield select(getCurrentApp)

    const getPayerOrInsured = (path) =>
      ENABLE_PREFILL_DDA_FORM ? get(`payer.${path}`, app) || get(`insured.${path}`, app) : ''
    const defaultTitle = getPayerOrInsured('title.value')
    const defaultLastName = getPayerOrInsured('lastName')
    const defaultFirstName = getPayerOrInsured('firstName')
    const defaultIdNo = getPayerOrInsured('idNo.citizenId')
    const defaultRelation = getPayerOrInsured('relationshipToInsured.value')
    const defaultRelationText = getPayerOrInsured('relationshipToInsured.text')
    const defaultTitleText = getPayerOrInsured('title.text')

    yield put(setDefaultAppValue('ATP.relations.value', defaultRelation))
    yield put(setDefaultAppValue('ATP.title.value', defaultTitle))
    yield put(setDefaultAppValue('ATP.title.text', defaultTitleText))
    yield put(setDefaultAppValue('ATP.relations.text', defaultRelationText))

    if (ENABLE_PREFILL_IDTYPE_DROPDOWN_DA_FORM) {
      const defaultIdTypeText = getPayerOrInsured('idType.text')
      const defaultIdTypeValue = getPayerOrInsured('idType.value')
      const defaultIdNoPassport = getPayerOrInsured('idNo.passport')

      yield put(setDefaultAppValue('ATP.idType.text', defaultIdTypeText))
      yield put(setDefaultAppValue('ATP.idType.value', defaultIdTypeValue))
      yield put(setDefaultAppValue('ATP.idNo.passport', defaultIdNoPassport))
    }

    yield put(setDefaultAppValue('ATP.idNo.citizenId', defaultIdNo))
    yield put(setDefaultAppValue('ATP.firstName', defaultFirstName))
    yield put(setDefaultAppValue('ATP.lastName', defaultLastName))

    //! this is for prefilling the ATP form
    yield put(saveAppData())
  }
  if (ENABLE_AIUW) {
    yield put(requestAIUW())
  }
}

export const onRequestAIUW = function*() {
  if (!getToggles().ENABLE_AIUW) {
    return
  }
  // might change it already call nai
  const user = yield select(getIdentityUser)
  const policyId = yield select(getPolicyIdCurrentApp)
  const payload = { policyId: policyId }
  try {
    const response = yield call(saveAIUWRequest, user, payload)

    yield put(setAppValue('AIUW.status', 'requested'))
    yield put(setAppValue('AIUW.requestTimestamp', Date.now()))
    yield put(setAppValue('AIUW.requestResult', response))
    yield put(saveAppData())
  } catch (e) {
    yield put(setAppValue('AIUW.status', 'Requested'))
    yield put(setAppValue('AIUW.requestTimestamp', Date.now()))
    yield put(setAppValue('AIUW.requestResult', e))
    yield put(saveAppData())
  }
}

export const onGetAIUWStatus = function*() {
  const MAX_RETRIES = 5
  const RETRY_DELAY = 5000 // 5 seconds

  const finishedPaymentSection = yield select(isFinishedPaymentSection)
  if (!finishedPaymentSection) {
    return
  }
  let AIUW = yield select(getAIUWCurrentApp)
  if (!getToggles().ENABLE_AIUW || AIUW === undefined) {
    return
  } else if (AIUW && (AIUW.status === 'success' || AIUW.status === 'failed')) {
    const requireUW = yield select(getRequiredUWByDoc)
    const app = yield select(getCurrentApp)
    const completedPaid = isCompletedPaid(app)

    if (requireUW && AIUW.result.data.AZFrontEndResult === 'STP') {
      AIUW.result.data.AZFrontEndResult = 'RUW'
      AIUW.Acknowledge = false
      AIUW.completedPaid = completedPaid
      AIUW.result.data.AZAIUWRUWClass = 'A'
      yield put(setAppValue('AIUW', AIUW))
      yield put(saveAppData())
      return
    }

    if (!requireUW && AIUW.result.data.AZFrontEndResult === 'RUW' && AIUW.result.data.FrontEndResult === 'STP') {
      AIUW.result.data.AZFrontEndResult = 'STP'
      AIUW.Acknowledge = false
      AIUW.completedPaid = completedPaid
      AIUW.result.data.AZAIUWRUWClass = AIUW.result.data.AIUWRUWClass
      yield put(setAppValue('AIUW', AIUW))
      yield put(saveAppData())
      return
    }

    if (AIUW.completedPaid !== completedPaid) {
      AIUW.completedPaid = completedPaid
      AIUW.Acknowledge = false
      yield put(setAppValue('AIUW', AIUW))
      yield put(saveAppData())
      return
    }
    return
  } else if (AIUW && AIUW.status === 'requesting') {
    return
  }
  // handle requesting here

  yield put(setAppValue('AIUW.status', 'requesting'))
  yield put(setAppValue('AIUW.getStatusTimestamp', Date.now()))

  try {
    const user = yield select(getIdentityUser)
    const policyId = yield select(getPolicyIdCurrentApp)
    const payload = { policyId: policyId }
    // check response and retry here

    for (let i = 0; i < MAX_RETRIES; i++) {
      try {
        let response = yield call(getAIUWStatus, user, payload)

        if (response?.code !== 200) {
          //TODO: X need to check with BSA and other parties about AI UW status code and message
          console.log(`AIUW Attempt ${i + 1}: Status still in progress`)

          if (i === MAX_RETRIES - 1) {
            yield put(setAppValue('AIUW.result.error', response))
            throw new Error('Maximum retries exceeded while waiting for completion')
          }

          yield delay(RETRY_DELAY)
        } else {
          // success case
          yield put(setAppValue('AIUW.status', 'success'))
          // check document after call success
          const requireUW = yield select(getRequiredUWByDoc)
          response.data.AZFrontEndResult = requireUW ? 'RUW' : response.data.FrontEndResult
          response.data.AZAIUWRUWClass =
            requireUW && response.data.FrontEndResult === 'STP' ? 'A' : response.data.AIUWRUWClass
          yield put(setAppValue('AIUW.result', response))
          yield put(saveAppData())
          break
        }
      } catch (error) {
        console.log('AIUW request error inner catch')
        if (i === MAX_RETRIES - 1) {
          throw error
        }
        yield delay(RETRY_DELAY)
      }
    }
  } catch (e) {
    console.log('AIUW request error outer catch')
    yield put(setAppValue('AIUW.status', 'failed'))
    const RUWResult = {
      AIUWDecision: 'RUW',
      AZFrontEndResult: 'RUW',
      FrontEndResult: 'RUW',
      BLStatus: 'FAIL',
      BLReasons: [],
      AIUWReasons: [],
      AIUWMemos: [],
      AZAIUWRUWClass: '',
      AIUWRUWClass: '',
    }
    const app = yield select(getCurrentApp)
    const completedPaid = isCompletedPaid(app)
    yield put(setAppValue('AIUW.completedPaid', completedPaid))
    yield put(setAppValue('AIUW.result.data', RUWResult))
    yield put(setDefaultAppValue('AIUW.result.error', e))
    yield put(saveAppData())
  }
}

export default function*(service, history) {
  yield all([
    takeEvery('CREATE_POLICY', onCreatePolicy, service, getPolicyPayload, getDocuments),
    takeEvery('UPDATE_POLICY', onUpdatePolicy, service, getPolicyPayload, getDocuments),
    takeEvery('VERIFY_POLICY', onVerifyPolicy, service),
    takeEvery('CONFIRM_SUBMITTED_POLICY', onConfirmSubmittedPolicy, service, history),
    takeEvery('SUBMIT_POLICY', onSubmitPolicy, service),
    takeEvery('START_REMOTE_SUBMIT_PAGE', onStartRemoteSubmitPage, service, getPolicyPayload),
    takeEvery('START_SUBMIT_PAGE', onStartSubmitPage, service),
    takeEvery('GET_AIUW_STATUS', onGetAIUWStatus, service),
    takeEvery('REQUEST_AIUW', onRequestAIUW, service),
  ])
}
